/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ description, lang, meta, title, image }) => {
  const { pathname } = useLocation();
  const { 
    site : {
      siteMetadata: {
        description: defaultDescription,
        image: defaultImage,
        title: defaultTitle,
        author,
        url
      }
    }
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image,
            url
          }
        }
      }
    `
  );
  const metaDescription = description || defaultDescription;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${defaultTitle}`}
      meta={[{
          name: `description`,
          content: metaDescription,
        }, {
          property: `og:type`,
          content: `website`,
        }, {
          property: `og:title`,
          content: title,
        }, {
          property: `og:description`,
          content: metaDescription,
        }, {
          property: `og:image`,
          content: image || defaultImage,
        }, {
          property: `og:url`,
          content: `${url}${pathname}`,
        }, {
          name: `twitter:creator`,
          content: author,
        }, {
          name: `twitter:title`,
          content: title,
        }, {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  image: undefined
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  image: PropTypes.string
};

export default SEO;
