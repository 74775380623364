import React from "react";
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import EscapeLogo from "../assets/svg/escape-logo--white.svg";
import AppStoreLogo from "../assets/svg/app-store-logo.svg";
import PlayStoreLogo from "../assets/svg/play-store-logo.svg";

const Footer = () => (
  <>
    <footer className="section section--footer">
      <div className="footer__section footer__section--logo">
        <EscapeLogo className="footer__logo" />
      </div>
      <div className="footer__section footer__section--store-logos">
        <OutboundLink
          href="https://apps.apple.com/us/app/escape/id1460848804"
          target="_blank"
          rel="noreferrer"
          eventLabel="ios"
          eventCategory="download_app"
          eventAction="download_app_button_click"
        >
          <AppStoreLogo className="store-logos__logo store-logos__logo--app-store" />
        </OutboundLink>
        <PlayStoreLogo className="store-logos__logo store-logos__logo--play-store" />
      </div>
      <div className="footer__section footer__section--contact">
        <h3 className="footer__section-subheading footer__section-subheading--contact subheading--grey">
          Contact us
        </h3>
        <ul>
          <li>
            <h4>For iOS or Android app support:</h4>
            <OutboundLink
              href="mailto:support@escapetheapp.com"
              target="_blank"
              rel="noreferrer"
              eventAction="email_button_click"
              eventCategory="contact"
              eventLabel="support"
            >
              support@escapetheapp.com
            </OutboundLink>
          </li>
          <li>
            <h4>For press queries:</h4>
            <OutboundLink
              href="mailto:press@escapetheapp.com"
              target="_blank"
              rel="noreferrer"
              eventAction="'email_button_click"
            >
              press@escapetheapp.com
            </OutboundLink>
          </li>
          <li>
            <h4>For all other queries:</h4>
            <OutboundLink
              href="mailto:hello@escapetheapp.com"
              target="_blank"
              rel="noreferrer"
              eventAction="'email_button_click"
            >
              hello@escapetheapp.com
            </OutboundLink>
          </li>
        </ul>
      </div>
      <div className="footer__section footer__section--legal">
        <h3 className="footer__section-subheading footer__section-subheading--legal subheading--grey">
          Legal
        </h3>
        <ul>
          <li>
            <Link
              key="legal-link--privacy"
              className="legal-link legal-link--privacy"
              to="privacy">
              Privacy policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer__section footer__section--copyright">
        © Escape The App,{" "}
        <OutboundLink
          href="http://www.antiblanks.com"
          target="_blank"
          rel="noreferrer"
          eventAction="'antiblanks_button_click"
        >
          Antiblanks Limited
        </OutboundLink>{" "}
        2020.
      </div>
    </footer>
  </>
);

export default Footer;
