import { Link } from "gatsby";
import React, { useState } from "react";
import HamburgerMenu from 'react-hamburger-menu';
import EscapeLogo from "../components/images/escape-logo--white";

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const _toggleMenu = () => (
    menuOpen ? setMenuOpen(false) : setMenuOpen(true)
  );
  const _closeMenu = () => setMenuOpen(false);
  const menuProps = {
    isOpen: menuOpen,
    menuClicked: _toggleMenu,
    strokeWidth: 1,
    rotate: 0,
    color: 'white',
    borderRadius: 0,
    animationDuration: 0.5
  };
  return (
    <div className="menu">
      <div className={`menu__links ${menuOpen ? 'menu__links--open' : 'menu__links--closed'}`}>
        <div className="menu__links-inner">
        <Link className="menu__links-link" onClick={_closeMenu} to="/">Home</Link>
        <Link className="menu__links-link" onClick={_closeMenu} to="/vr-powered-live-video-games">
          VR Live Avatar
        </Link>
        <Link className="menu__links-link" onClick={_closeMenu} to="/blog">Blog</Link>
        </div>
      </div>
      <div className="menu__button-container">
        <HamburgerMenu
          className="menu__button menu__button--large"
          {...menuProps}
          width={32}
          height={24}
        />
        <HamburgerMenu
          className="menu__button menu__button--small"
          {...menuProps}
          width={24}
          height={18}
        />
      </div>
    </div>
  );
};

const Header = () => (
  <header className="header">
    <Link to="/">
      <EscapeLogo />
    </Link>
    <Menu />
  </header>
);

export default Header;
